<template>
  <div class="home-index frame-content-common-2022">
    <!-- TÊN TRANG, NÚT TẠO MỚI GÓI -->
    <div class="pageTitleBlock">
      <h2 style="margin-left: 16px;">Dashboard</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeIndex",
  metaInfo() {
    return {
      title: "ChinaOrder - Đặt hàng xuyên biên giới",
    };
  },
  components: {},
  data() {
    return {
      loading: true,
    };
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style lang="css" scoped>
.home-index {
}
</style>
