<template>
  <div id="app">
    <div class="is-login
      ">
      <div v-if="logged" class="headerTop">
        <header-top></header-top>
      </div>
      <div class="d-flex contenView" :class="[logged == false ? 'full-contenView' : '']">
        <div class="app-menu-left" v-if="logged" :class="[showText ? 'app-menu-left-full' : '']">
          <menu-left @toggleMenu="toggleMenuParent"></menu-left>
        </div>
        <div
          class="content-right app-content-right"
          :class="[showText ? 'app-content-right-full' : '' || logged == false ? 'full-screen' : '']"
        >
          <transition :name="logged ? 'slide-fade' : ''">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
    <div class="not-login">
      <!-- <router-view /> -->
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapGetters, mapActions } from "vuex";
import ls from "local-storage";
import MenuLeft from "@/views/layout/MenuLeft";
import HeaderTop from "@/views/layout/HeaderTop";
import axios from "axios";
export default {
  name: "App",
  metaInfo () {
    return {
      title: this.title,
      titleTemplate: 'Buyder | %s',
      showText: false,
    }
  },
  components: {
    MenuLeft,
    HeaderTop,
  },
  computed: {
    ...mapGetters("account", ["logged"]),
    ...mapGetters(["path", "full_path"]),
  },
  data() {
    return {
      showText: false,
    };
  },
  mounted() {
    this.checkLoged();
  },
  methods: {
    ...mapActions("account", ["getAccountInfo"]),
    checkLoged() {
      let authen = ls.get("authen");
      if (!authen) {
        if (this.path !== "register") {
          this.$router.push({ name: "account-login" });
        }
      } else {
        if (!this.logged) {
          this.getAccountInfo();
        }
      }
    },

    async toggleMenuParent(value) {
      console.log('toggleMenuParent');
      this.showText = !value;
      console.log(this.showText);

      this.checkAvatar();
    },

    async checkAvatar() {
      const response = await axios.get("account");

      document.querySelector('img.avatar')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
      document.querySelector('.el-submenu__title .image-left')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
      document.querySelector('.avatar-info img')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
    },
  },
};
</script>

<style>
.headerTop {
  height: 50px
}
.contenView {
  height: 100%;
  min-height: calc(100vh - 50px);
  overflow-x: hidden;
  width: 100%;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.full-screen {
  padding: 0 !important;
  background: #fff !important;
}
.full-contenView {
  width: 100%;
  height: calc(100vh);
}

.content-right {
  width: calc(100% - 80px);
  background: #F2F3F7;
  padding-left: 16px;
}

.content-right>div {
  padding-right: 16px;
}

.app-content-right-full {
  width: 85% !important;
  transition: 0.3s;
}
.app-menu-left {
  width: 64px;
}
.app-menu-left-full {
  width: 15% !important;
}

/********************************/
/**** CUSTOM LẠI FILTER FORM ****/
/********************************/
.form-filter-block {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 6px 0 rgba(13, 10, 44, 0.08);
  display: flex;
}

.form-filter-item {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.form-filter-item label {
  text-align: left;
}

.form-filter-item .el-select {
  width: 100%;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #9D62F5;
}

.el-date-table td.today span,
.el-date-table td.available:hover {
  color: #9D62F5;
}

.el-date-table td.current:not(.disabled) span {
  background-color: #9D62F5;
}

.el-table .cell {
  word-break: break-word;
}

/************************/
/**** CUSTOM BUTTONS ****/
/************************/
.el-button--primary {
  background-color: #9D62F5;
  border-color: #9D62F5;
  border-radius: 12px !important;
}

.el-button--primary.is-plain {
  background-color: #FFF;
  border-color: #9D62F5;
  border-radius: 12px !important;
}


.changePasswordForm {
  border-radius: 12px;
}

.changePasswordForm input:focus-visible {
  border: 1px solid #6C5CE7;
}

.changePasswordForm button:nth-child(1) {
  border-color: #6C5CE7;
  border-radius: 12px;
  height: 36px;
  width: 160px;
  background-color: #FFF;
}
</style>
