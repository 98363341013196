import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "home-index",
    component: Home,
    beforeEnter: auth.requireAuth,
  },
  {
    path: "/login",
    name: "account-login",
    component: () => import("@/views/account/Login.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  {
    path: "/register",
    name: "account-register",
    component: () => import("@/views/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Profile
  {
    path: "/profile",
    name: "account-profile",
    component: () => import("@/views/account/Profile.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Quản lý công ty
  {
    path: "/company-management",
    name: "company-management",
    component: () => import("@/views/pages/CompanyManagement.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Danh sách thông tin xác thực
  {
    path: "/identifier-list",
    name: "identifier-list",
    component: () => import("@/views/pages/IdentifierList.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Gói
  {
    path: "/pack-list",
    name: "pack-list",
    component: () => import("@/views/pages/PackList.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Lịch sử thanh toán
  {
    path: "/payment-history",
    name: "payment-history",
    component: () => import("@/views/pages/PaymentHistory.vue"),
    beforeEnter: auth.requireAuth,
  },
  // Tài liệu sản phẩm
  {
    path: "/api-document",
    name: "api-document",
    component: () => import("@/views/pages/ApiDocument.vue"),
    beforeEnter: auth.requireAuth,
    alias: ["/api-create"],
  },
  // Tạo tài liệu sản phẩm
  {
    path: "/api-create",
    name: "api-create",
    component: () => import("@/views/pages/CreateDocument.vue"),
    beforeEnter: auth.requireAuth
  },
  // Sửa tài liệu sản phẩm
  {
    path: "/api-edit/:id",
    name: "api-edit",
    component: () => import("@/views/pages/EditDocument.vue"),
    beforeEnter: auth.requireAuth
  },
  // báo cáo khách hàng tìm kiếm
  {
    path: "/report-client-search",
    name: "report-client-search",
    component: () => import("@/views/pages/ReportClientSearch.vue"),
    beforeEnter: auth.requireAuth
  },
  // báo cáo  theo từ khóa
  {
    path: "/report-key-search",
    name: "report-key-search",
    component: () => import("@/views/pages/ReportKeySearch.vue"),
    beforeEnter: auth.requireAuth
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
