<template>
  <div
    class="component-header-top d-flex justify-content-between align-items-center"
  >
    <div class="seach-global">
      <img src="@/static/icon/header-top/logo.svg" alt="">
    </div>

    <div class="d-flex align-items-center">
      <!-- menu  -->
      <div class="list-menu d-flex flex-row">
        <div v-for="menu in list" :key="menu.text">
          <div class="menu-item">
            <span>{{ menu.text }}</span>
          </div>
        </div>
      </div>

      <div
        class="dropdown-account"
        ref="dropdownProfile"
        @click="showMenuTop = !showMenuTop"
      >
        <img
          v-if="accountInfo.avatar"
          :src="accountInfo.avatar_path"
          class="avatar"
        />
        <span v-else><img src="@/static/icon/header-top/account.svg"/></span>
        <span class="name">
        {{ accountInfo.name }}
        <img class="down" src="@/static/icon/header-top/down.svg"/>
      </span>
        <ul v-if="showMenuTop">
          <router-link
            :to="{ name: 'account-profile', params: {} }"
            @click.native.stop="showMenuTop = false"
          >
            <span>Thông tin tài khoản</span>
          </router-link>
          <a class="divider"></a>
          <a
            href="javascript:void(0)"
            @click="openChangePasswordForm()"
          >Đổi mật khẩu</a>
          <a class="divider"></a>
          <a @click.stop.stop="confirmLogout">
            <span>Thoát tài khoản</span>
          </a>
        </ul>
      </div>
    </div>

    <el-dialog
      title="Đổi mật khẩu"
      :visible.sync="isChangingPassword"
      width="40%"
      :close-on-click-modal="false"
      class="changePasswordForm"
    >
      <el-form ref="changePasswordForm" :model="editingPassword" :rules="changePasswordFormRules">
        <el-form-item label="Mật khẩu cũ" prop="old">
          <el-input
            type="password"
            v-model="editingPassword.old"
            @input="editingPassword.old = $event"
          ></el-input>
        </el-form-item>
        <el-form-item label="Mật khẩu mới" prop="new">
          <el-input
            type="password"
            v-model="editingPassword.new"
            @input="editingPassword.new = $event"
          ></el-input>
        </el-form-item>
        <el-form-item label="Xác nhận mật khẩu" prop="confirm">
          <el-input
            type="password"
            v-model="editingPassword.confirm"
            @input="editingPassword.confirm = $event"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeChangePasswordForm()">Hủy bỏ</el-button>
        <el-button :loading="isButtonConfirming" type="primary" @click="handleSubmitChangePasswordRequest()">Xác nhận
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import ls from "local-storage";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "LayoutHeaderTop",

  data() {
    return {
      seach: "",
      showMenuTop: false,
      list: [
        // {
        //   text: "Trung tâm API",
        //   url: "",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "Bảng giá",
        //   url: "",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "Tổ chức",
        //   url: "",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "Ứng dụng",
        //   url: "",
        //   name: "",
        //   rootPermission: [],
        // },
        // {
        //   text: "APIs của tôi",
        //   url: "",
        //   name: "",
        //   rootPermission: [],
        // },
      ],

      // BUTTONS
      isButtonConfirming: false,

      // THAY ĐỔI MẬT KHẨU
      isFormValid: false,
      isChangingPassword: false,
      editingPassword: {
        old: "",
        new: "",
        confirm: ""
      },
      changePasswordFormRules: {
        old: [
          {required: true, message: 'Mật khẩu cũ không được để trống'}
        ],
        new: [
          {required: true, message: 'Mật khẩu mới không được để trống'}
        ],
        confirm: [
          {required: true, message: 'Xác nhận mật khẩu không được để trống'},
          {
            validator: this.confirmNewPasswordValidator,
            message: 'Xác nhận phải trùng với mật khẩu mới',
            trigger: 'blur'
          }
        ],
      }
    };
  },

  mounted() {
    document.addEventListener("click", this.documentClick);
    this.checkAvatar();
  },

  destroyed() {
    document.removeEventListener("click", this.documentClick);
  },

  methods: {
    async checkAvatar() {
      const response = await axios.get("account");

      document.querySelector('img.avatar')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
      document.querySelector('.el-submenu__title .image-left')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
      document.querySelector('.avatar-info img')
        ?.setAttribute('src', response.data.avatar_path ?? require('@/static/icon/header-top/account.svg'));
    },

    documentClick(e) {
      let el = this.$refs.dropdownProfile;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.showMenuTop = false;
      }
      this.checkAvatar();
    },

    ...mapActions("account", ["removeAccountInfo"], ["getAccountInfo"]),

    mouseLeave() {
      this.showMenuTop = false;
    },

    confirmLogout() {
      this.$confirm("Xác nhận thoát tài khoản", "Warning", {
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy",
        type: "warning",
      })
        .then(() => {
          ls('out', true);
          this.removeAccountInfo();
        })
        .catch(() => {
        });
    },

    // LOGICS LIÊN QUAN TỚI VIỆC THAY ĐỔI MẬT KHẨU
    openChangePasswordForm() {
      this.isChangingPassword = true;
    },

    closeChangePasswordForm() {
      this.isChangingPassword = false;
      this.resetPasswordForm();
      this.$refs.changePasswordForm.resetFields();
      this.isButtonConfirming = false;
    },

    resetPasswordForm() {
      this.editingPassword = {
        old: "",
        new: "",
        confirm: ""
      }
    },

    validateChangePasswordForm() {
      this.$refs.changePasswordForm.validate(valid => {
        this.isFormValid = valid;
      });
    },

    async handleSubmitChangePasswordRequest() {
      this.validateChangePasswordForm();
      if  (! this.isFormValid) {
        this.$notify({
          title: 'Cảnh báo',
          message: 'Kiểm tra lại thông tin mật khẩu',
          type: 'warning'
        });
        return;
      }

      this.isButtonConfirming = true;

      try {
        const data = {
          email: this.accountInfo.email,
          password: this.editingPassword.new
        };
        const response = await axios.post('auth/change-password', data);

        if (response.code == 200) {
          this.$notify({
            title: 'Thành công',
            message: 'Đổi mật khẩu thành công',
            type: 'success'
          });
          this.isChangingPassword = false;
        } else {
          let firstValueInData = null;
          for (const key in response.data) {
            firstValueInData = response.data[key];
            break;
          }
          this.$notify({
            title: 'Lỗi khi thay đổi mật khẩu',
            message: firstValueInData,
            type: 'error'
          });
        }
      } catch (error) {
        this.$notify({
          title: 'Lỗi khi thay đổi mật khẩu',
          message: error,
          type: 'error'
        });
      }

      this.isButtonConfirming = false;
    },

    confirmNewPasswordValidator(rule, value, callback) {
      // Lấy giá trị của "Mật khẩu mới" và "Xác nhận mật khẩu mới"
      const newPassword = this.editingPassword.new;
      const confirmPassword = value;

      // Kiểm tra xem giá trị "Xác nhận mật khẩu mới" có giống với "Mật khẩu mới" hay không
      if (newPassword === confirmPassword) {
        // Nếu khớp, gọi callback() với không có lỗi
        callback();
      } else {
        // Nếu không khớp, gọi callback() với lỗi
        callback(new Error('Xác nhận mật khẩu mới không khớp'));
      }
    },
  },

  computed: {
    ...mapGetters("account", ["accountInfo"]),
  },
};
</script>

<style lang="css" scoped>
.component-header-top {
  height: 50px;
  padding: 0px 15px;
  background: #6049CD;
}

.seach-global {
  min-width: 280px;
}

.dropdown-account {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0px 3px 0px 10px;
  position: relative;
}

.dropdown-account img {
  margin-right: 8px;
}

.dropdown-account .down {
  width: 10px;
  margin-right: 0px;
  margin-left: 5px;
}

.dropdown-account .name {
  color: #fff;
  font-size: 12px;
}

.dropdown-account ul {
  position: absolute;
  top: 50px;
  right: 0px;
  margin: 0px;
  width: 200px;
  background-color: #fff;
  padding: 5px 0px 5px 0px;
  z-index: 1000;
  border: 1px solid #f2f2f2;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.dropdown-account ul a {
  list-style-type: none;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  text-decoration: none;
  color: #2c3e50;
}

.dropdown-account ul a:hover {
  background-color: #f2f2f2;
}

.dropdown-account ul a.divider {
  height: 1px;
  background-color: #ccc;
  margin-bottom: 5px;
}

/* meun item  */
.component-header-top .menu-item {
  padding: 10px 10px 14px 10px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}
</style>

<style>
.component-header-top input {
  border-radius: 25px;
  height: 28px;
  font-size: 12px;
  border: none;
}

.component-header-top .el-input__icon {
  line-height: 28px;
}

.avatar {
  max-width: 100%;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

/*******************/
/**** EL DIALOG ****/
/*******************/
.el-dialog {
  border-radius: 24px;
  max-width: 600px;
  margin-top: 3vh !important;
}

.el-dialog__close {
  display: none !important;
}

.el-dialog__body {
  padding: 8px 20px;
}

.el-dialog__footer button {
  border-color: #6C5CE7;
  border-radius: 12px;
  height: 36px;
  width: 160px;
}

.el-dialog__footer button:nth-child(1) {
  color: #6C5CE7;
}

.el-dialog__footer button:nth-child(1):hover {
  color: #6C5CE7;
  border-color: #6C5CE7;
  background-color: rgba(200, 200, 200, 0.1);
}

.el-dialog__footer button:nth-child(2) {
  background-color: #6C5CE7;
}

.el-dialog__footer button:nth-child(2):hover {
  border-color: #6C5CE7;
  opacity: 0.9;
}

.changePasswordForm input {
  border: 1px solid #C0C4CC;
  height: 40px;
}

.changePasswordForm input:focus-visible {
  border: 1px solid #6C5CE7;
}

.changePasswordForm button:nth-child(1) {
  border-color: #6C5CE7;
  border-radius: 12px;
  height: 36px;
  width: 160px;
}
</style>
